export default function LoadingLogo() {
    return (
        <div className="loading-container">
            <img
                src="https://drive.google.com/uc?export=view&id=121Ih6P27o44RC5ADE6_r2TQyp4bozt6O&auto=format&fit=crop&w=1587&q=80"
                alt="Loading Logo"
                className="loading-logo"
            />
        </div>
    );
}

