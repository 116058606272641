import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import React, { lazy, Suspense } from 'react';
import LoadingLogo from './Components/LoadingLogo';



const Base = lazy(() => import('./Pages/Base'));
const Login = lazy(() => import('./Pages/Login'));
const Register = lazy(() => import('./Pages/Register'));
const Admin = lazy(() => import('./Pages/Admin'));

function App() {
  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<LoadingLogo />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin/*" element={<PrivateRoute admin><Admin /></PrivateRoute>} />
            <Route path="/*" element={<PrivateRoute><Base /></PrivateRoute>} />
            <Route path="/loading" element={<LoadingLogo />} />
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
