import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = React.createContext();
const baseURL = process.env.REACT_APP_BASE_URL;

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);  // <-- New state

  async function getUserData() {
    try {
      const response = await axios.get(`${baseURL}/auth/user/`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200) {
        console.log('Success');
        setIsAuthenticated(true);
        setIsAdmin(response.data.user_info.is_admin);  // <-- Set admin status from the response
      }
    } catch (error) {
      setIsAuthenticated(false);
      setIsAdmin(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  // Provide getUserData function as part of the context
  return (
    <AuthContext.Provider value={{ isAuthenticated, getUserData, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
